const state = {
};

const getters = {
  clientCode (state) {
    return state.clientCode;
  },
  customerId (state) {
    return state.customerId;
  },
  customerPriceList (state) {
    return state.customerPriceList;
  },
  userLoggedOn (state) {
    const loggedOn = state.userLoggedOn !== 'False';
    return loggedOn;
  },
  currencyCode(state) {
    return state.currencyCode;
  },
  bespokeUserInformation (state) {
    return state.bespokeUserInformation;
  }
};

const mutations = {
  setGlobalUserInformation (state, globalUserInformationObj) {
    Object.assign(state, globalUserInformationObj);
  }
};

const actions = {
  setGlobalUserInformation ({ commit }, globalUserInformation) {
    commit('setGlobalUserInformation', globalUserInformation);
  }
};

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
